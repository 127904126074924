<template>
	<div class="pui-form-layout">
		<v-row dense class="mt-4">
			<pui-field-set :title="$t('form.pilotage.servicedata')">
				<v-layout wrap>
					<v-flex xs12 md3>
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									:id="`portname-${modelName}`"
									:attach="`portname-${modelName}`"
									:label="this.$t('form.pilotage.portname')"
									toplabel
									clearable
									required
									:disabled="!isCreatingElement || disableserviceInfo"
									v-model="parentObject"
									modelName="port"
									:itemsToSelect="itemsToSelectPort"
									:modelFormMapping="{ id: 'portid' }"
									:key="'portid_' + portidKey"
									itemValue="id"
									itemText="portname"
									reactive
									:fixedFilter="filterByUserPortsAndAuthority"
								></pui-select>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 md3>
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									:id="`statusname-${modelName}`"
									:attach="`statusname-${modelName}`"
									:label="this.$t('form.pilotage.statusname')"
									toplabel
									clearable
									:disabled="true"
									v-model="parentObject"
									modelName="technicalnauticalservicestatuspa"
									:itemsToSelect="[{ tnsstatuscode: this.parentObject.tnsstatuscode }]"
									:modelFormMapping="{ tnsstatuscode: 'tnsstatuscode' }"
									itemValue="tnsstatuscode"
									itemText="statusname"
									reactive
									:fixedFilter="filterByAuthority"
								></pui-select>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 md6>
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									:id="`visitid-${modelName}`"
									:attach="`visitid-${modelName}`"
									:label="$t('form.pilotage.portcallnumber')"
									toplabel
									clearable
									required
									:disabled="!parentObject.portid || !isCreatingElement || disableserviceInfo"
									v-model="parentObject"
									modelName="visit"
									:itemsToSelect="itemsToSelectVisits"
									:modelFormMapping="{ id: 'visitid' }"
									:itemValue="['id']"
									:itemText="
										(item) =>
											`${item.portcallnumber} - ${item.vesselname} - ${this.$dateTimeUtils.getLocalFormattedDate(
												item.eta,
												'DD/MM/YYYY HH:mm'
											)} - ${this.$dateTimeUtils.getLocalFormattedDate(item.etd, 'DD/MM/YYYY HH:mm')} - ${item.status}`
									"
									:order="{ eta: 'asc' }"
									:fixedFilter="portidFixedFilter"
									:key="'portid_' + portidKey"
									reactive
									detailComponentName="visit-form"
									detailModelName="visit"
								></pui-select>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 md6 v-if="$store.getters.isMovementsActives">
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									:id="`movementid-${modelName}`"
									:attach="`movementid-${modelName}`"
									:label="$t('form.pilotage.movementnum')"
									toplabel
									clearable
									:disabled="!parentObject.visitid || !isCreatingElement || disableserviceInfo"
									v-model="parentObject"
									modelName="movement"
									:itemsToSelect="itemsToSelectMovementNum"
									:modelFormMapping="{ id: 'movementid' }"
									itemValue="id"
									:itemText="(item) => getMovementText(item)"
									:order="{ nummovement: 'asc' }"
									:fixedFilter="movementVisitidFixedFilter"
									:key="'movementid_' + visitidKey"
									required
									reactive
									detailComponentName="movement-form"
									detailModelName="movement"
								></pui-select>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 md6>
						<v-layout wrap>
							<v-flex xs12>
								<pui-select
									:id="`stopid-${modelName}`"
									:attach="`stopid-${modelName}`"
									:label="$t('form.pilotage.stopnum')"
									toplabel
									clearable
									:disabled="!parentObject.visitid || !isCreatingElement || disableStopInfo"
									v-model="parentObject"
									modelName="stop"
									:itemsToSelect="itemsToSelectStops"
									:modelFormMapping="{ id: 'stopid' }"
									itemValue="id"
									:itemText="(item) => getStopItemText(item)"
									:order="{ num: 'asc' }"
									:fixedFilter="visitidFixedFilter"
									:key="'stopid_' + visitidKey"
									reactive
									detailComponentName="stop-form"
									detailModelName="stop"
								></pui-select>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-field-set>
		</v-row>
		<v-row dense>
			<pui-field-set :title="$t('form.pilotage.reqdate')">
				<v-layout wrap>
					<v-flex xs12 md6>
						<v-layout wrap>
							<v-flex xs12>
								<pui-date-field
									:id="`startdatereq-${modelName}`"
									:label="$t('form.pilotage.startdatereq')"
									v-model="parentObject.startdatereq"
									toplabel
									clearable
									:disabled="formDisabled || parentObject.tnsstatuscode !== 'RQ'"
									time
									:max="parentObject.enddatereq"
								></pui-date-field>
							</v-flex>
						</v-layout>
					</v-flex>
					<v-flex xs12 md6>
						<v-layout wrap>
							<v-flex xs12>
								<pui-date-field
									:id="`enddatereq-${modelName}`"
									:label="$t('form.pilotage.enddatereq')"
									v-model="parentObject.enddatereq"
									toplabel
									clearable
									:disabled="formDisabled || parentObject.tnsstatuscode !== 'RQ'"
									time
									:min="parentObject.startdatereq"
								></pui-date-field>
							</v-flex>
						</v-layout>
					</v-flex>
				</v-layout>
			</pui-field-set>
		</v-row>
		<v-row dense>
			<v-col cols="6" v-if="hasServiceStatusId('AU')">
				<pui-field-set :title="$t('form.pilotage.planneddate')">
					<v-layout wrap>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`startdateplanned-pilotage`"
										v-model="parentObject.startdateplanned"
										:label="$t('form.pilotage.startdateplanned')"
										:disabled="formDisabled || parentObject.tnsstatuscode !== 'AU'"
										toplabel
										time
										:max="parentObject.enddateplanned"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`enddateplanned-pilotage`"
										v-model="parentObject.enddateplanned"
										:label="$t('form.pilotage.enddateplanned')"
										:disabled="formDisabled || parentObject.tnsstatuscode !== 'AU'"
										toplabel
										time
										:min="parentObject.startdateplanned"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-col>
			<v-col cols="6" v-if="hasServiceStatusId('PL')">
				<pui-field-set :title="$t('form.pilotage.estimateddate')">
					<v-layout wrap>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`startdateestimated-pilotage`"
										v-model="parentObject.startdateestimated"
										:label="$t('form.pilotage.startdateestimated')"
										:disabled="formDisabled || parentObject.tnsstatuscode !== 'PL'"
										toplabel
										time
										:max="parentObject.enddateestimated"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`enddateestimated-pilotage`"
										v-model="parentObject.enddateestimated"
										:label="$t('form.pilotage.enddateestimated')"
										:disabled="formDisabled || parentObject.tnsstatuscode !== 'PL'"
										toplabel
										time
										:min="parentObject.startdateestimated"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="6">
				<pui-field-set :title="$t('form.pilotage.actualdate')">
					<v-layout wrap>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`startdateactual-${modelName}`"
										:label="$t('form.pilotage.startdateactual')"
										v-model="parentObject.startdateactual"
										toplabel
										:disabled="formDisabled || parentObject.tnsstatuscode !== 'IN'"
										time
										:max="parentObject.enddateactual"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-date-field
										:id="`enddateactual-${modelName}`"
										:label="$t('form.pilotage.enddateactual')"
										v-model="parentObject.enddateactual"
										toplabel
										:disabled="formDisabled || parentObject.tnsstatuscode !== 'FI'"
										time
										:min="parentObject.startdateactual"
									></pui-date-field>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-col>
			<v-col cols="12">
				<pui-field-set :title="$t('form.pilotage.maininfo')">
					<v-layout wrap>
						<v-flex xs12 md4>
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										:id="`pilotid-${modelName}`"
										:attach="`pilotid-${modelName}`"
										:label="$t('form.pilotage.pilotname')"
										toplabel
										clearable
										:disabled="formDisabled || disableForm"
										required
										v-model="parentObject"
										modelName="pilot"
										:itemsToSelect="itemsToSelectPilotName"
										:modelFormMapping="{ pilotid: 'pilotid' }"
										itemValue="pilotid"
										itemText="fullname"
										:order="{ fullname: 'asc' }"
										reactive
									></pui-select>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md4>
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										:id="`maneuvertype-${modelName}`"
										:attach="`maneuvertype-${modelName}`"
										:label="$t('form.pilotage.maneuvertype')"
										toplabel
										clearable
										:disabled="formDisabled || disableForm"
										required
										v-model="parentObject"
										modelName="maneuvertype"
										:itemsToSelect="itemsToSelectManeuverType"
										:modelFormMapping="{ maneuvertypecode: 'maneuvertype' }"
										itemValue="maneuvertypecode"
										itemText="description"
										reactive
									></pui-select>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md4 v-if="!isCreatingElement && parentObject.tnsstatuscode === 'AN'">
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										:id="`cancelationreason-${modelName}`"
										:attach="`cancelationreason-${modelName}`"
										v-model="parentObject"
										:label="$t('form.pilotage.cancelationreason')"
										toplabel
										clearable
										disabled
										modelName="cancelationreason"
										:itemsToSelect="[{ id: parentObject.cancelationreasonid }]"
										:modelFormMapping="{ id: 'cancelationreasonid' }"
										itemValue="id"
										itemText="description"
										reactive
									></pui-select>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md12>
							<v-layout wrap>
								<v-flex xs12>
									<pui-text-area
										:id="`comments-pilotage`"
										v-model="parentObject.comments"
										:label="$t('form.pilotage.comments')"
										:disabled="formDisabled || disableForm"
										toplabel
										maxlength="300"
									></pui-text-area>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'pilotage-form-main-tab',
	data() {
		return {
			portidKey: 0,
			visitidKey: 0,
			visitidFixedFilter: null,
			movementVisitidFixedFilter: null,
			visiteta: null,
			visitetd: null,
			statusAp: [],
			disableForm: null,
			parentModelName: null,
			parentModelNameVisit: null,
			disableserviceInfo: false,
			disableStopInfo: false
		};
	},
	props: {
		modelName: {
			type: String,
			required: true
		},
		parentObject: {
			type: Object,
			required: true
		},
		formDisabled: {
			type: Boolean,
			required: true
		},
		isCreatingElement: {
			type: Boolean,
			required: true
		},
		modelPk: {
			type: Object,
			required: true
		}
	},
	methods: {
		setPilotageDates() {
			const opts = {
				model: 'movement',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.parentObject.movementid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data[0]) {
					this.doSetPilotageDates(response.data.data[0]);
				}
			});
		},
		doSetPilotageDates(movement) {
			const opts = {
				model: 'portauthorityvariable',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'in',
							data: [
								'TIME_PILOT_FROM_SEA_TO_SHIP',
								'TIME_PILOT_FROM_SHIP_TO_BERTH',
								'TIME_PILOT_FROM_BERTH_TO_SHIP',
								'TIME_PILOT_FROM_SHIP_TO_SEA'
							]
						},
						{
							field: 'port_authority_id',
							op: 'eq',
							data: this.$store.getters.getPortAuthorityId
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data) {
					response.data.data.forEach((e) => {
						if (
							!this.parentObject.startdatereq &&
							e.value &&
							e.value !== '-' &&
							e.variable === 'TIME_PILOT_FROM_SEA_TO_SHIP' &&
							movement.type === 1
						) {
							this.parentObject.startdatereq = new Date(movement.starttime);
							this.parentObject.startdatereq.setMinutes(this.parentObject.startdatereq.getMinutes() - Number(e.value));
						} else if (
							!this.parentObject.enddatereq &&
							e.value &&
							e.value !== '-' &&
							e.variable === 'TIME_PILOT_FROM_SHIP_TO_BERTH' &&
							movement.type === 1
						) {
							this.parentObject.enddatereq = new Date(movement.endtime);
							this.parentObject.enddatereq.setMinutes(this.parentObject.enddatereq.getMinutes() + Number(e.value));
						} else if (
							!this.parentObject.startdatereq &&
							e.value &&
							e.value !== '-' &&
							e.variable === 'TIME_PILOT_FROM_BERTH_TO_SHIP' &&
							movement.type === 2
						) {
							this.parentObject.startdatereq = new Date(movement.starttime);
							this.parentObject.startdatereq.setMinutes(this.parentObject.startdatereq.getMinutes() - Number(e.value));
						} else if (
							!this.parentObject.enddatereq &&
							e.value &&
							e.value !== '-' &&
							e.variable === 'TIME_PILOT_FROM_SHIP_TO_SEA' &&
							movement.type === 2
						) {
							this.parentObject.enddatereq = new Date(movement.endtime);
							this.parentObject.enddatereq.setMinutes(this.parentObject.enddatereq.getMinutes() + Number(e.value));
						}
					});
				}
			});
		},
		getStopItemText(item) {
			let startdate = item.ata ? item.ata : item.etaauth ? item.etaauth : item.eta;
			let enddate = item.atd ? item.atd : item.etdauth ? item.etdauth : item.etd;

			return `${item.num} - ${this.$dateTimeUtils.getLocalFormattedDate(startdate, 'DD/MM/YYYY HH:mm')}  ${
				enddate ? '-' + this.$dateTimeUtils.getLocalFormattedDate(enddate, 'DD/MM/YYYY HH:mm') : ''
			} - ${item.status} - ${item.location}`;
		},
		updateValidations(visitid) {
			const opts = {
				model: 'visit',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.parentObject.visitid ? this.parentObject.visitid : visitid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data[0]) {
					this.visiteta = response.data.data[0].eta;
					this.visitetd = response.data.data[0].etd;
				}
			});
		},
		hasServiceStatusId(tnsstatuscode) {
			return this.statusAp.includes(tnsstatuscode);
		},
		getMovementText(item) {
			return `${item.nummovement} - ${item.movementtype}  ${
				item.starttime ? '-' + this.$dateTimeUtils.getLocalFormattedDate(item.starttime, 'DD/MM/YYYY HH:mm') : ''
			}  ${item.endtime ? '-' + this.$dateTimeUtils.getLocalFormattedDate(item.endtime, 'DD/MM/YYYY HH:mm') : ''}
											- ${item.movementstatus}`;
		}
	},
	computed: {
		itemsToSelectPilotName() {
			return [{ pilotid: this.parentObject.pilotid }];
		},
		itemsToSelectManeuverType() {
			return [{ maneuvertypecode: this.parentObject.maneuvertype }];
		},
		itemsToSelectPort() {
			return [{ id: this.parentObject.portid }];
		},
		filterByUserPortsAndAuthority() {
			const userPorts = this.$store.getters.getPortsIds;
			const userPortAuthority = window.localStorage.getItem('workingPortAuthorityId');
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'id', op: 'in', data: userPorts },
					{ field: 'portauthorityid', op: 'eq', data: userPortAuthority }
				]
			};
		},
		filterByAuthority() {
			const userPortAuthority = window.localStorage.getItem('workingPortAuthorityId');
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'portauthorityid', op: 'eq', data: userPortAuthority }]
			};
		},
		itemsToSelectVisits() {
			return [{ id: this.parentObject.visitid }];
		},
		itemsToSelectStops() {
			return [{ id: this.parentObject.stopid }];
		},
		itemsToSelectMovementNum() {
			return [{ id: this.parentObject.movementid }];
		},
		visitStatusCodes() {
			return this.$store.getters.getActiveVisitStatusid;
		},
		movementStatusCodes() {
			return this.$store.getters.getActiveMovementStatusid;
		},
		portidFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'portid', op: 'eq', data: this.parentObject.portid },
					{ field: 'statusid', op: 'in', data: this.visitStatusCodes }
				]
			};
		}
	},
	watch: {
		'parentObject.portid'(newVal) {
			if (!newVal) {
				this.visitidFixedFilter = null;
				this.movementVisitidFixedFilter = null;
				this.parentObject.portid = null;
				this.parentObject.stopid = null;
				this.parentObject.movementid = null;
			}
			this.portidKey += 1;
		},
		'parentObject.visitid'(newVal) {
			if (newVal) {
				this.visitidFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'visitid', op: 'eq', data: newVal }]
				};
				this.movementVisitidFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'visitid', op: 'eq', data: newVal },
						{ field: 'movementstatuscode', op: 'in', data: this.movementStatusCodes }
					]
				};
			} else {
				this.visitidFixedFilter = null;
				this.movementVisitidFixedFilter = null;
				this.parentObject.stopid = null;
			}
			this.visitidKey += 1;
			this.updateValidations();
		},
		'parentObject.movementid'(newVal) {
			if (newVal) {
				this.setPilotageDates();
			} else {
				this.parentObject.startdatereq = null;
				this.parentObject.enddatereq = null;
			}
		}
	},
	created() {
		this.$puiEvents.$on(`onPuiSelect_selectedItems-movementid-${this.modelName}`, (newVal) => {
			console.log(newVal);
			if (newVal.model) {
				this.parentObject.stopid = newVal.model.stopto;
			}
		});
	},

	mounted() {
		if (!this.isCreatingElement) {
			//Get pilotage data stop
			const opts = {
				model: 'pilotage',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'service_id',
							op: 'eq',
							data: this.modelPk.serviceid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data[0].locationid) {
					//Get pilotage data visit
					this.updateValidations(response.data.data[0].visitid);
				}
			});

			if (this.parentObject.tnsstatuscode) {
				this.disableForm = this.parentObject.tnsstatuscode === 'AN' ? true : false;
			}
		} else {
			this.parentObject.tnsstatuscode = 'RQ';
			if (this.$route.params.parentpk) {
				const currentPath = this.$router.currentRoute.path.split('/');
				this.parentModelName = currentPath[1];
				this.parentModelNameVisit = currentPath[4];
				if (this.parentModelName.includes('movement') || this.parentModelNameVisit.includes('movement')) {
					this.parentObject.movementid = JSON.parse(atob(this.$route.params.parentpk)).id;
					const opts = {
						model: 'movement',
						filter: {
							groupOp: 'and',
							groups: [],
							rules: [
								{
									field: 'id',
									op: 'eq',
									data: this.parentObject.movementid
								}
							]
						}
					};
					this.$puiRequests.postRequest('/puisearch', opts, (response) => {
						if (response.data.data[0]) {
							this.parentObject.portid = response.data.data[0].portid;
							this.parentObject.visitid = response.data.data[0].visitid;
							this.disableserviceInfo = true;
							if (response.data.data[0].type === 1) {
								this.parentObject.stopid = response.data.data[0].stopto;
								this.disableStopInfo = true;
							} else if (response.data.data[0].type === 2) {
								this.parentObject.stopid = response.data.data[0].stopfrom;
								this.disableStopInfo = true;
							} else {
								this.parentObject.stopid = null;
							}
						}
					});
				}
			} else if (this.$route.params.visitpk) {
				const currentPath = this.$router.currentRoute.path.split('/');
				this.parentModelName = currentPath[1];
				if (this.parentModelName.includes('visit')) {
					this.disableserviceInfo = true;
					this.parentObject.visitid = JSON.parse(atob(this.$route.params.visitpk)).id;
					const opts = {
						model: 'visit',
						filter: {
							groupOp: 'and',
							groups: [],
							rules: [
								{
									field: 'id',
									op: 'eq',
									data: this.parentObject.visitid
								}
							]
						}
					};
					this.$puiRequests.postRequest('/puisearch', opts, (response) => {
						if (response.data.data[0]) {
							this.parentObject.portid = response.data.data[0].portid;
						}
					});
				}
			}
		}
		const opts2 = {
			model: 'technicalnauticalservicestatuspa',
			filter: {
				groupOp: 'and',
				groups: [],
				rules: [
					{
						field: 'port_authority_id',
						op: 'eq',
						data: window.localStorage.getItem('workingPortAuthorityId')
					}
				]
			}
		};
		this.$puiRequests.postRequest('/puisearch', opts2, (response) => {
			if (response.data) {
				//Get towage data visit
				this.statusAp = response.data.data.map((sap) => {
					return sap.tnsstatuscode;
				});
			}
		});
	},
	destroyed() {
		this.$puiEvents.$off(`onPuiSelect_selectedItems-movementid-${this.modelName}`);
	}
};
</script>
